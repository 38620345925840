<template>
  <b-card no-body>
    <b-card-header v-b-toggle.filterBody>
      Фильтр
    </b-card-header>
    <b-collapse
      id="filterBody"
      visible
      class="card-body"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col md="3">
            <b-form-group
              class="form-group-el-select"
              label="Менеджеры СТЛ"
            >
              <el-select-clearable
                v-model="filter.manager"
                class="form-control"
                name="manager"
                placeholder="Начните вводить название"
                clearable
                :multiple="true"
                :filterable="true"
                :remote="true"
                :remote-method="customersRoutesManagersList"
                :loading="loading"
                @clear="customersRoutesManagersList"
              >
                <el-option
                  v-for="item in managers"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select-clearable>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
          >
            <b-form-group
              class="form-group-el-select"
              label="Перевозчик"
            >
              <el-select
                v-model="filter.contractor"
                class="form-control"
                name="contractor"
                placeholder="Начните вводить название компании перевозчика"
                clearable
                :filterable="true"
                :remote="true"
                :multiple="true"
                reserve-keyword
                :remote-method="customersContractorsList"
                :loading="loading"
                @clear="customersContractorsList"
              >
                <el-option
                  v-for="item in contractors"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              class="form-group-el-select"
              label="Списки списков перевозчика"
            >
              <el-select-clearable
                v-model="filter.contractors_list"
                class="form-control"
                name="route-way"
                placeholder="Добавить группу перевозчиков"
                clearable
                :filterable="true"
                :remote="true"
                :multiple="true"
                :remote-method="getCustomersContractorsListsList"
                :loading="loading"
                @clear="getCustomersContractorsListsList"
              >
                <el-option
                  v-for="item in listOfContractors"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select-clearable>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              class="form-group-el-select"
              label="Период"
            >
              <el-select
                v-model="filter.period"
                class="form-control"
                @change="setDefaultDate"
              >
                <el-option
                  v-for="item in periods"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Дата от">
              <el-date-picker-input
                v-model="filter.date_from"
                name="date_from"
                :format="getDateFormat"
                :type="filter.period"
                :picker-options="pickerOptions"
                @input="startDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Дата до">
              <el-date-picker-input
                v-model="filter.date_to"
                name="date_to"
                :format="getDateFormat"
                :type="filter.period"
                :picker-options="pickerOptions"
                @input="endDate"
                @change="setDataToReports"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              class="form-group-el-select"
              label="Тип распределения"
            >
              <el-select-clearable
                v-model="filter.type"
                class="form-control"
                name="type"
                clearable
                :loading="loading"
              >
                <el-option
                  v-for="item in types"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select-clearable>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Исключать рейсы без базовых тарифов">
              <b-checkbox
                v-model="filter.hide_without_base_tariff"
                name="hide_without_base_tariff"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6">
                <b-form-group
                    class="form-group-el-select"
                    label="Отделение отправитель"
                >
                  <el-select-clearable
                      v-model="filter.start_point"
                      name="contractor"
                      placeholder="Начните вводить название компании перевозчика"
                      class="w-100 form-control"
                      clearable
                      :filterable="true"
                      :remote="true"
                      reserve-keyword
                      :multiple="true"
                      :remote-method="getFirstDepartmentsList"
                      :loading="loading"
                      @clear="getFirstDepartmentsList"
                  >
                    <el-option
                        v-for="item in firstDepartments"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    />
                  </el-select-clearable>
                </b-form-group>
                <b-row>
                  <b-col class="m-1">
                    <el-button
                        type="primary"
                        class="w-100"
                        @click="filter.start_point = []"
                    >
                      Сбросить всё
                    </el-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-form-group
                    class="form-group-el-select"
                    label="Отделение получатель"
                >
                  <el-select-clearable
                      v-model="filter.end_point"
                      name="contractor"
                      placeholder="Начните вводить название компании перевозчика"
                      class="w-100 form-control"
                      clearable
                      :filterable="true"
                      :remote="true"
                      reserve-keyword
                      :multiple="true"
                      :remote-method="getLastDepartmentsList"
                      :loading="loading"
                      @clear="getLastDepartmentsList"
                  >
                    <el-option
                        v-for="item in lastDepartments"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    />
                  </el-select-clearable>
                </b-form-group>
                <b-row>
                  <b-col class="m-1">
                    <el-button
                        type="primary"
                        class="w-100"
                        @click="filter.end_point = []"
                    >
                      Сбросить всё
                    </el-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-collapse>
  </b-card>
</template>

<script>
import ElDatePickerInput from '../../../components/ElDatePickerInput.vue';
import ElSelectClearable from '@/components/ElSelectClearable.vue';
import moment from 'moment';
import {
  departmentsList,
  routeOwnersList,
  customersContractorsList,
  customersContractorsListsList,
} from '../../../services/api';

export default {
  name: 'RouteReportsFilter',
  components: {
    ElSelectClearable,
    ElDatePickerInput,
  },
  props: ['category'],
  data() {
    return {
      isDateChanged: false,
      managers: [],
      contractors: [],
      listOfContractors: [],
      firstDepartments: [],
      lastDepartments: [],
      types: [
        {value: 'manual', label: 'Вручную'},
        {value: 'tender', label: 'Тендер'},
        {value: 'auction', label: 'Аукцион'},

      ],
      periods: [
        {value: 'date', label: 'Произвольный'},
        {value: 'week', label: 'Недельный'},
        {value: 'month', label: 'Месячный'},
      ],
      filter: {
        hide_without_base_tariff: true,
        type: 'manual',
        period: 'date',
        date_from: null,
        date_to: null,
        contractors_list: null,
        start_point: [],
        end_point: [],
        manager: null,
        contractor: null,
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: true,
    };
  },
  computed: {
    getDateFormat() {
      if (this.filter.period === 'date') {
        return 'dd.MM.yyyy';
      }
      if (this.filter.period === 'week') {
        return 'Неделя WW';
      }
      if (this.filter.period === 'month') {
        return 'Меcяц MM';
      }
      return '';
    },
    isDisabledManagerCategory() {
      switch (this.category) {
      case 'without-managers':
        return true;
        break;
      case 'contractors':
        return true;
        break;
      case 'money':
        return true;
        break;
      case 'money-by-routes':
        return true;
        break;
      case 'route-number-dist':
        return true;
        break;
      case 'route-money-dist':
        return true;
        break;
      case 'savings-and-overspending':
        return true;
        break;
      default:
        break;
      }
    },
    isDisabledNDSCategory() {
      switch (this.category) {
      case 'without-managers':
        return true;
        break;
      case 'with-managers':
        return true;
        break;
      case 'completed':
        return true;
        break;
      default:
        break;
      }
    },
    isDisabledContractorCategory() {
      switch (this.category) {
      case 'without-managers':
        return true;
        break;
      case 'with-managers':
        return true;
        break;
      case 'money':
        return true;
        break;
      default:
        break;
      }
    },
  },
  watch: {
    category(val) {
      if (val === 'without-managers') {
        this.filter.manager = null;
        this.filter.with_nds = null;
      }
      if (val !== 'completed') {
        this.filter.contractor = null;
      }
      if (val === 'contractors') {
        this.filter.manager = null;
      }
    },
  },
  updated() {
    this.setDataToReports();
  },
  mounted() {
    this.getFirstDepartmentsList();
    this.getLastDepartmentsList();
    this.customersRoutesManagersList();
    this.customersContractorsList();
    this.getCustomersContractorsListsList();
    this.setDataToReports();
  },
  created() {
    this.setDefaultDate();
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    getNowDates() {
      const d = new Date();
      const firstDay = new Date();
      const lastDay = new Date(d.setDate(d.getDate() + 1));
      return {firstDay: this.formatDate(firstDay), lastDay: this.formatDate(lastDay)};
    },
    getNowWeekDates() {
      const firstDayOfCurrentWeek = moment().startOf('week').toDate();
      const lastDayOfPrevWeek = moment(firstDayOfCurrentWeek).subtract(1, 'days')._d;

      const lastWeek = moment(lastDayOfPrevWeek).subtract(3, 'weeks').toDate();
      const firstDayOfLastWeek = moment(lastWeek).startOf('week').toDate();

      return {firstDay: this.formatDate(firstDayOfLastWeek), lastDay: this.formatDate(lastDayOfPrevWeek)};
    },
    getNowMonthDates() {
      const fullYear = new Date().getFullYear();
      const month = new Date().getMonth();

      const d = new Date(fullYear, month, 1);
      const pastMonth = new Date(d.setMonth(d.getMonth() - 4));
      // Первый день первого месяца в периоде
      const firstDayOfPastMonth = new Date(pastMonth.getFullYear(), pastMonth.getMonth(), 1);
      // Последний день предыдущего месяца в периоде
      const lastDayOfPrevMonth = moment(new Date(fullYear, month, 1)).subtract(1, 'days')._d;

      return {firstDay: this.formatDate(firstDayOfPastMonth), lastDay: this.formatDate(lastDayOfPrevMonth)};
    },
    getWeekDates(d) {
      d = new Date(d);
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      const monday = new Date(d.setDate(diff));
      const sunday = new Date(d.setDate(diff + 6));

      return {monday: this.formatDate(monday), sunday: this.formatDate(sunday)};
    },
    getMonthDates(d) {
      const firstDay = new Date(d);
      const firstDayOfNextMonth = new Date(d.setMonth(d.getMonth() + 1));
      const lastDay = moment(firstDayOfNextMonth).subtract(1, 'days')._d;

      return {firstDay, lastDay};
    },
    startDate(val) {
      this.isDateChanged = true;
      if (val == null) {
        this.filter.date_from = null;
      }
      if (this.filter.period === 'date' && !(val == null)) {
        this.filter.date_from = this.formatDate(val);
      }
      if (this.filter.period === 'week' && !(val == null)) {
        const prevWeekDays = this.getWeekDates(val);
        this.filter.date_from = this.formatDate(prevWeekDays.monday);
      }
      if (this.filter.period === 'month' && !(val == null)) {
        const mDays = this.getMonthDates(val);
        this.filter.date_from = this.formatDate(mDays.firstDay);
      }
    },
    endDate(val) {
      this.isDateChanged = true;
      if (val == null) {
        this.filter.date_to = null;
      }
      if (this.filter.period === 'date' && !(val == null)) {
        this.filter.date_to = this.formatDate(val);
      }
      if (this.filter.period === 'week' && !(val == null)) {
        const prevWeekDays = this.getWeekDates(val);
        this.filter.date_to = this.formatDate(prevWeekDays.sunday);
      }
      if (this.filter.period === 'month' && !(val == null)) {
        const mDays = this.getMonthDates(val);
        this.filter.date_to = this.formatDate(mDays.lastDay);
      }
    },
    async getCustomersContractorsListsList(name) {
      this.loading = true;
      const params = {limit: 10};
      if (name) {
        params.name = name;
      }
      const response = await customersContractorsListsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.listOfContractors = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
      }
      this.loading = false;
    },
    async getFirstDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 10};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.firstDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async getLastDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 10};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.lastDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async customersRoutesManagersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await routeOwnersList(params);
      if (response && response.status === 200) {
        this.managers = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.manager) {
        await this.customersRoutesSelectedManagersList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedManagersList() {
      const selectedManagers = {};
      this.managers.forEach((item, index) => {
        selectedManagers[`id[${index}]`] = item;
      });
      const response = await routeOwnersList(selectedManagers);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.managers = this.managers.filter((x) => x.value !== item.id);
          this.managers.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async customersContractorsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await customersContractorsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.contractors = response.data.items.map((item) => {
          return {
            value: item.contractor.id,
            text: item.contractor.name,
          };
        });
      }
      if (this.filter.contractor) {
        this.customersSelectedContractorsList();
      }
      this.loading = false;
    },
    async customersSelectedContractorsList() {
      this.loading = true;
      const response = await customersContractorsList(this.$store.state.user.ownerId, {
        'id[0]': this.filter.contractor,
      });
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.contractors = this.contractors.filter((x) => x.value !== item.contractor.id);
          this.contractors.unshift({
            value: item.contractor.id,
            text: item.contractor.name,
          });
        });
      }
      this.loading = false;
    },
    setDefaultDate() {
      if (!this.isDateChanged) {
        if (this.filter.period === 'date') {
          const defaultDates = this.getNowDates();
          this.filter.date_from = defaultDates.firstDay;
          this.filter.date_to = defaultDates.lastDay;
        }
        if (this.filter.period === 'week') {
          const defaultDates = this.getNowWeekDates();
          this.filter.date_from = defaultDates.firstDay;
          this.filter.date_to = defaultDates.lastDay;
        }
        if (this.filter.period === 'month') {
          const defaultDates = this.getNowMonthDates();
          this.filter.date_from = defaultDates.firstDay;
          this.filter.date_to = defaultDates.lastDay;
        }
      }
    },
    setDataToReports() {
      this.$emit('filter', this.filter);
    },
  },
};
</script>
